<div>
  <div mat-dialog-content class="matDialogContent">
    <p>
      {{ "common.project_updated" | translate }}
      <mat-icon class="iconColor" aria-hidden="false">task_alt </mat-icon>
    </p>
  </div>
  <div mat-dialog-actions class="matDialogContent">
    <button class="btn-add dashboardMargin" (click)="gotoDashboard()">
      {{ "common.button.back_to_dashboard" | translate }}
    </button>
    <button
      class="btn-cancel"
      [mat-dialog-close]="true"
      cdkFocusInitial
      (click)="reload()"
    >
      {{ "common.button.continue_editing" | translate }}
    </button>
  </div>
</div>
