<div>
  <div class="svg-header">
    <div class="row-header container">
      <div class="col svg">
        <div>
          <svg width="60" height="60" viewBox="0 0 60 60">
            <svg:g [node]="component" [hostComponent]="'header'"></svg:g>
          </svg>
        </div>
      </div>
      <div class="col label">
        <div class="svg-title row regular-font">
          <b>{{ component.label }}</b>
        </div>
        <div class="row"></div>
      </div>
    </div>
  </div>

  <div class="row select-msg">Select the component from below list</div>
  <hr class="hrBg" />

  <div class="carousel">
    <div class="ingress">
      <div class="rule-header">Ingress Components({{ ingress.length }})</div>
      <div class="container">
        <carousel>
          <ng-container *ngFor="let node of ingress">
            <ng-container *carouselItem>
              <div class="item">
                <svg
                  width="60"
                  height="60"
                  viewBox="0 0 60 60"
                  [class.highlighted]="outboundBatch.indexOf(node) !== -1"
                  (click)="multiSelectOutbound(node)"
                >
                  <svg:g [node]="node" [hostComponent]="''"></svg:g>
                </svg>
              </div>
              <div class="svg-label">
                {{ node.label | split: "- " | slice: 0:8 }}...
              </div>
            </ng-container>
          </ng-container>
        </carousel>
        <ng-container *ngIf="ingress.length == 0">
          <div class="no-comp"><br />No components found</div>
        </ng-container>
      </div>
    </div>
    <div class="egress">
      <div class="rule-header">Egress Components({{ egress.length }})</div>
      <div class="container">
        <carousel>
          <ng-container *ngFor="let node of egress">
            <ng-container *carouselItem>
              <div class="item">
                <svg
                  width="60"
                  height="60"
                  viewBox="0 0 60 60"
                  [class.highlighted]="inboundBatch.indexOf(node) !== -1"
                  (click)="multiSelectInbound(node)"
                >
                  <svg:g [node]="node" [hostComponent]="''"></svg:g>
                </svg>
              </div>
              <div class="svg-label">
                {{ node.label | split: "- " | slice: 0:8 }}...
              </div>
            </ng-container>
          </ng-container>
        </carousel>
        <ng-container *ngIf="egress.length == 0">
          <div class="no-comp"><br />No components found</div>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="actions">
    <button class="btn-cancel" [mat-dialog-close]="true">CANCEL</button>
    <button class="btn-add" mat-raised-button (click)="addNodes(component)">
      ADD
    </button>
  </div>
</div>
