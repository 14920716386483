import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'split' })
export class SplitPipe implements PipeTransform {
  transform(val: any, params: string): string[] {
    const splits: any = val.split(params);
    if (splits.length > 1) {
      return splits.pop();
    } else {
      return [val].pop();
    }
  }
}
