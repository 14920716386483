export const environment = {
  production: false,
  region: 'us-east-1',
  identityPoolId: 'us-east-1:7ecf3b6d-fa28-4152-8d19-2a5fb135c43a',
  unAuthRoleARN:
    'arn:aws:iam::507831496546:role/kloudjet-infra-identity-ua-CognitoUnAuthorizedRole-196AJZYMTCZAI',
  authRoleARN:
    'arn:aws:iam::507831496546:role/kloudjet-infra-identity-uat-CognitoAuthorizedRole-MJDTXWEA4SC5',
  userPoolId: 'us-east-1_qcDP6tV3l',
  clientId: '2uen3vmp9obhp7j9l5n10vk00t',
  getIdPoolLogins(userRole?: string): string {
    switch (userRole) {
      default:
        return (
          'cognito-idp.' + this.region + '.amazonaws.com/' + this.userPoolId
        );
    }
  },
  cognito_idp_endpoint: '',
  cognito_identity_endpoint: '',
  sts_endpoint: '',
  ASSETS_URL: 'https://assets.ascendmedia.online',
  TWELVE_HOURS_MILLI: 4.32e7,
  API_HOST: '.execute-api.us-east-1.amazonaws.com',
  SAVE_PROJ:
    'https://tja8n7voc0.execute-api.us-east-1.amazonaws.com/uat/v1/project/saveEditProject',
  GET_PROJ_COMP:
    'https://tja8n7voc0.execute-api.us-east-1.amazonaws.com/uat/v1/project/get',
  CREATE_PROJECT:
    'https://tja8n7voc0.execute-api.us-east-1.amazonaws.com/uat/v1/project/create',
  DASHBOARD_URL: 'https://uat.kloudjet.com/',
  KLOUDJET_STORAGE_BUCKET: 'kloudjet-admin-portal-storage-uat',
  COOKIE_DOMAIN: '.kloudjet.com',
  GENERATE_SIGNED_S3_URL:
    'https://89pclfh410.execute-api.us-east-1.amazonaws.com/uat/v1/template/generateSignedS3Url',
};
