import { Component, OnInit, Input, Injectable } from '@angular/core';
import { NodeService } from '../../../services/node.service';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { ConfirmationDialogComponent } from '../../../modules/confirmation-dialog/confirmation-dialog.component';
import { ProjectDetailsComponent } from '../../../modules/project-details/project-details.component';
import { CookieService } from 'ngx-cookie-service';
import { NotifierToastService } from '../../shared-services/notifier-toast.service';
@Injectable({
  providedIn: 'root',
})
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() templateName: any;
  isSaved = false;
  scratch = false;
  constructor(
    private nodeService: NodeService,
    private dialog: MatDialog,
    private cookieService: CookieService,
    private notifierToast: NotifierToastService
  ) {}

  ngOnInit(): void {
    const id = this.cookieService.get('projectId');
    if (id) {
      this.scratch = false;
    } else {
      this.scratch = true;
    }
  }
  async saveproject(): Promise<any> {
    this.isSaved = true;
    await this.nodeService.saveProject().subscribe(
      (data: any) => {
        console.log(data);
        this.cookieService.delete(
          'tempTemplate',
          '/',
          environment.COOKIE_DOMAIN
        );
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          width: '450px',
        });
        this.isSaved = false;
      },
      (err: any) => {
        this.notifierToast.showNotification('error', 'Please Try Saving Again');
      }
    );
  }
  clickMethod(): void {
    if (
      confirm(
        'Are you sure to leave this page?\nChanges you made will not be saved.'
      )
    ) {
      this.cookieService.delete('projectId', '/', environment.COOKIE_DOMAIN);
      this.cookieService.delete('loggedInUser', '/', environment.COOKIE_DOMAIN);
      this.cookieService.delete('tempTemplate', '/', environment.COOKIE_DOMAIN);
      window.location.href = environment.DASHBOARD_URL;
    }
  }
  savedetails() {
    const dialogRef = this.dialog.open(ProjectDetailsComponent, {
      width: '490px',
      data: {},
    });
  }
}
