import {
  Injectable,
  Component,
  OnInit,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  setItem(name: string, item: string): void {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(name, item);
    }
  }

  getItem(name: any): any {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem(name);
    }
    return '';
  }

  removeItem(name: string): void {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem(name);
    }
  }

  clear(): void {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.clear();
    }
  }
}
