<svg
  class="node"
  [matTooltip]="node.label"
  [matTooltipPosition]="'above'"
  viewBox="0 0 100 100"
  (click)="Selectcomponent(node)"
>
  <rect
    *ngIf="!(hostComponent == 'canvas') && !(hostComponent == 'control_panele')"
    width="100%"
    height="100%"
    rx="5"
    class="svg-background"
  />
  <rect
    *ngIf="hostComponent == 'control_panele'"
    width="100%"
    height="100%"
    rx="5"
    class="svg-background"
  />

  <g>
    <image
      x="50%"
      y="50%"
      transform="translate(-35,-35)"
      width="70"
      height="70"
      [attr.xlink:href]="node.svgURL"
      *ngIf="node.svgURL"
    />
  </g>
  <text
    class="nodeLabel"
    alignment-baseline="central"
    text-align="center"
    [attr.x]="10"
    [attr.y]="92"
    *ngIf="hostComponent == 'canvas'"
  >
    {{ node.label | split: "- " | slice: 0:8 }}...
  </text>
</svg>
