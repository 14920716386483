import { Inject, Injectable } from '@angular/core';
import { Edge } from '@swimlane/ngx-graph/lib/models';
import { BehaviorSubject, Observable } from 'rxjs';
import { Inode } from '../interfaces/node.model';
import { map as map } from 'src/app/constants/node-map';
import * as linksArray from 'src/app/constants/links';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { LocalStorageService } from './local-storage.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class NodeService {
  template: any = {};
  componentmap: any;
  selecteddata: any;
  selectedNodeList$: Observable<Inode[]>;
  selectedEdgeList$: Observable<Edge[]>;
  singleNodeSelected$: Observable<boolean>;
  deselectNode$: Observable<boolean>;
  prevstate = '';
  private _edge$: BehaviorSubject<Edge[]>;
  private _state$: BehaviorSubject<Inode[]>;
  private _singleNodeSelected$ = new BehaviorSubject(false);
  private _deselectNode$ = new BehaviorSubject(false);
  templateId: any = '';
  loggedInUser = {};
  emailId: any = '';
  projectName: any = '';
  protected constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private cookieService: CookieService
  ) {
    const newnode: Inode[] = [];
    const newEdge: Edge[] = [];
    this._edge$ = new BehaviorSubject(newEdge);
    this._state$ = new BehaviorSubject(newnode);
    this.selectedEdgeList$ = this._edge$.asObservable();
    this.selectedNodeList$ = this._state$.asObservable();
    this.singleNodeSelected$ = this._singleNodeSelected$.asObservable();
    this.deselectNode$ = this._deselectNode$.asObservable();
    // Fetching template id from kloudjet admin portal
    const queryString = window.location.search;
    const id = this.cookieService.get('projectId');
    this.templateId = id?.toString();
  }
  get state(): Inode[] {
    return this._state$.getValue();
  }
  tempSaveData() {
    const obj: any = {};
    const arr = this._state$.getValue();
    arr.forEach((element) => {
      const ingress: any[] = [];
      const egress: any[] = [];
      linksArray.links.forEach((link) => {
        if (link.source === element.id) {
          egress.push(link.target);
        }
        if (link?.target === element.id) {
          ingress.push(link.source);
        }
      });
      obj[element.id] = {
        ingress: ingress,
        egress: egress,
        name: element.label,
      };
    });
    this.template.componentMap = JSON.stringify(obj);
    let stringObj = JSON.stringify(this.template);
    this.cookieService.set('tempTemplate', stringObj, {
      domain: environment.COOKIE_DOMAIN,
    });
  }

  getdetails(): any {
    if (this.templateId || this.cookieService.get('projectId')) {
      if (!this.templateId) {
        return this.http.get(
          environment.GET_PROJ_COMP + '/' + this.cookieService.get('projectId')
        );
      }
      return this.http.get(environment.GET_PROJ_COMP + '/' + this.templateId);
    }
    return this.http.get(environment.GET_PROJ_COMP);
  }
  setState(nextState: Inode): void {
    this._state$.next([nextState, ...this._state$.getValue()]);
  }
  saveProject(): any {
    this.tempSaveData();
    return this.http.put(environment.SAVE_PROJ, this.template);
  }

  clickListener($event: any): void {
    if ($event.id) {
      this._singleNodeSelected$.next(false);

      this.prevstate = $event.id;
      return;
    } else {
      this.prevstate = '';
    }
  }

  drawEdge(nodeA: string, nodeB: string): void {
    const newEdge: Edge = {
      id: 'l-' + this.makeid(5),
      source: nodeA,
      target: nodeB,
    };
    if (!this.linkexist(nodeA, nodeB)) {
      this._edge$.next([newEdge, ...this._edge$.getValue()]);
      this.tempSaveData();
    }
  }
  linkexist(nodeA: string, nodeB: string): boolean {
    let flag = false;
    linksArray.links.forEach((element) => {
      if (element.source === nodeA && element.target === nodeB) {
        flag = true;
      }
    });
    return flag;
  }

  deleteNode(): void {
    const newNodeArr = this._state$
      .getValue()
      .filter((f) => f.id !== this.prevstate);
    this._deselectNode$.next(true);
    if (linksArray.links.length > 0) {
      for (let i = linksArray.links.length - 1; i >= 0; i--) {
        if (
          linksArray.links[i].source === this.prevstate ||
          linksArray.links[i].target === this.prevstate
        ) {
          linksArray.links.splice(i, 1);
        }
      }
    }
    this._edge$.next(linksArray.links);
    this._state$.next(newNodeArr);
    this.prevstate = '';
    this.tempSaveData();
  }

  makeid(l: number): string {
    let text = '';
    const charList = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    for (let i = 0; i < l; i++) {
      text += charList.charAt(Math.floor(Math.random() * charList.length));
    }
    return text;
  }
  getRules(): BehaviorSubject<Inode[]> {
    return this._state$;
  }
  selectedstate(selecteddata: any): void {
    this.selecteddata = selecteddata;
  }
  getselectedstate(): any {
    return this.selecteddata;
  }

  inittemplate(template: any): any {
    this.template = template;
    this.componentmap = JSON.parse(this.template.componentMap);
    return this.template.name;
  }

  initlinks(): void {
    for (const key in this.componentmap) {
      const temp = this.componentmap[key];

      if (temp?.ingress && temp.ingress.length > 0) {
        temp?.ingress?.forEach((ingressrule: string) => {
          this.drawEdge(ingressrule, key);
        });
      }
      if (temp?.egress && temp.egress.length > 0) {
        temp?.egress?.forEach((egressrule: string) => {
          this.drawEdge(key, egressrule);
        });
      }
    }
  }
  async cloneNode(node: Inode): Promise<any> {
    const n = JSON.parse(JSON.stringify(node));
    n.id = n.id;
    const existingNode = this._state$.getValue().filter((x) => x.id === n.id);
    if (existingNode.length === 0) {
      this.setState(n);
    } else {
    }
    this.tempSaveData();
  }

  initnodes(): void {
    const nodes: any[] = [];
    for (const key in this.componentmap) {
      if (Object.prototype.hasOwnProperty.call(this.componentmap, key)) {
        map.forEach((element: any) => {
          if (element.id === key) {
            nodes.push(element);
          }
        });
      }
    }
    nodes.forEach((element) => {
      element.id = element.id;
      this.cloneNode(element);
    });
    this.initlinks();
  }
  getgraphNodes(): string[] {
    const keyArray = this._state$.getValue().map(function (item: Inode) {
      return item['id'];
    });

    return keyArray;
  }

  deleteSelectednode(component: Inode): void {
    const index = map.indexOf(component);
    if (index > -1) {
      map.splice(index, 1);
    }
  }

  createProject(formobj: any) {
    this.tempSaveData();
    this.template.email = this.cookieService.get('loggedInUser');
    for (const field in formobj) {
      this.template[field] = formobj[field];
    }
    console.log(this.template);
    return this.http.put(environment.CREATE_PROJECT, this.template);
  }
}
