import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { tap, switchMap } from 'rxjs/operators';
import { from, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CognitoUtil } from 'src/app/services/cognito.service';
import { NotifierToastService } from 'src/app/shared/shared-services/notifier-toast.service';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  constructor(
    private cookieService: CookieService,
    private congnitoService: CognitoUtil,
    private notifierToast: NotifierToastService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let idToken = this.cookieService.get('idToken');

    let modifiedReq = request.clone({
      headers: request.headers
        .set('Content-Type', 'application/json')
        .set('Authorization', `Bearer ${idToken}`),
    });
    return next.handle(modifiedReq).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
          }
        },
        (err: any) => {
          console.log('err : ', err);
          if (err instanceof HttpErrorResponse) {
            // if (err.status === 403) {
            if (
              err.status === 0 &&
              err.statusText == 'Unknown Error' &&
              err!.url!.indexOf(environment.API_HOST) > -1
            ) {
              console.log('0');
              window.open(environment.DASHBOARD_URL, '_self');
            }
            if (
              (err.status === 401 || err.status === 403) &&
              err!.url!.indexOf(environment.API_HOST) > -1
            ) {
              this.notifierToast.showNotification(
                'error',
                'Session expired, Please Try Again!!'
              );
              from(this.congnitoService.genrateNewToken());
            } else {
            }
          }
        }
      )
    );
  }
}
