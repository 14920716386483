import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { NodeService } from 'src/app/services/node.service';
import {
  ISignedUrlBodyParams,
  UploadServiceService,
} from '../../services/upload-service.service';
import { AppComponent } from 'src/app/app.component';
import { CookieService } from 'ngx-cookie-service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { environment } from 'src/environments/environment';
import { NotifierToastService } from 'src/app/shared/shared-services/notifier-toast.service';
@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss'],
})
export class ProjectDetailsComponent implements OnInit {
  dataimage: any;
  @ViewChild('fileInput') fileInput: ElementRef;
  fileAttr = 'Choose File';
  uploading = false;
  projectDetail = new FormGroup({
    name: new FormControl(''),
    architectureDiagram: new FormControl(''),
    deploymentDiagram: new FormControl(''),
    logo: new FormControl(''),
    platform: new FormControl(''),
    technology: new FormControl(''),
  });
  formObj: any = {};
  folderName = 'diagram/';

  constructor(
    private dialog: MatDialog,
    private upload: UploadServiceService,
    private nodeService: NodeService,
    private app: AppComponent,
    private cookieService: CookieService,
    private notifierToast: NotifierToastService
  ) {}

  ngOnInit(): void {}

  uploadFiles(fileInputEvent: any, key: string): void {
    const uploadedFile: File = fileInputEvent.target.files[0];
    if (uploadedFile) {
      this.uploading = true;
      console.log('File Provided!');
      const signedUrlBodyParams: ISignedUrlBodyParams = {
        filePath: this.folderName + uploadedFile.name,
        fileType: uploadedFile.type,
      };
      this.upload.generateSignedUrl(signedUrlBodyParams).subscribe(
        (generatedUrl) => {
          this.upload
            .uploadFileToS3(generatedUrl, uploadedFile, uploadedFile.type)
            .subscribe(
              () => {
                const uploadedFileUrl = generatedUrl.split('?')[0];
                this.projectDetail.controls[key].setValue(uploadedFileUrl);
                this.uploading = false;
                console.log('File url - :', uploadedFileUrl);
              },
              (err) => {
                this.uploading = false;
                console.log('upload err', err);
              }
            );
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  saveProject() {
    this.uploading = true;
    for (const field in this.projectDetail.controls) {
      // 'field' is a string
      console.log(this.projectDetail.controls[field].value);
      this.formObj[field] = this.projectDetail.controls[field].value;
    }
    console.log(this.formObj);
    this.nodeService.createProject(this.formObj).subscribe(
      async (data: any) => {
        console.log(data);
        await this.cookieService.set('projectId', data.id, {
          domain: environment.COOKIE_DOMAIN,
        });
        this.cookieService.delete(
          'tempTemplate',
          '/',
          environment.COOKIE_DOMAIN
        );
        const dialogRef = this.dialog.closeAll();
        const dialogRef2 = this.dialog.open(ConfirmationDialogComponent, {
          width: '450px',
        });
        this.uploading = false;
      },
      (err: any) => {
        this.notifierToast.showNotification('error', 'Please Try Saving Again');
      }
    );
  }
}
