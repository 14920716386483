import {
  Output,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { DagreNodesOnlyLayout } from '@swimlane/ngx-graph';
import { Edge, Layout } from '@swimlane/ngx-graph/lib/models';
import * as shape from 'd3-shape';
import { Subject } from 'rxjs';
import { map as nodesArray } from 'src/app/constants/node-map';
import { Inode } from 'src/app/interfaces/node.model';
import { NodeService } from 'src/app/services/node.service';
import * as linksArray from 'src/app/constants/links';
@Component({
  selector: 'app-diag',
  templateUrl: './diag.component.html',
  styleUrls: ['./diag.component.scss'],
})
export class DiagComponent implements OnInit, OnChanges {
  public curve: any = shape.curveCatmullRom.alpha(1);
  public selectedId = '';
  @Output() selectedID = new EventEmitter<string>();
  @Output() selectednode = new EventEmitter<boolean>();
  @Input() isCenterEventAvailable = false;
  public layout: Layout = new DagreNodesOnlyLayout();
  public links: Edge[] = [];
  public nodes: Inode[] = [];
  nodeval: any = [];
  update$: Subject<boolean> = new Subject();
  isHovered = false;
  autoCenter = true;
  center$: Subject<boolean> = new Subject();
  zoomToFit$: Subject<boolean> = new Subject();

  constructor(private nodeService: NodeService) {
    this.nodeService.selectedNodeList$.subscribe((data) => {
      data.map((d) => {
        if (d.data) {
          d.data.largeImage = true;
        }
      });
      this.nodes = data;
      this.nodes.forEach((element) => {
        /* for(let data of nodesArray){
          if(data.id==element.id){
            let index = nodesArray.indexOf(data)
            console.log(index,element);
             if(index>-1)
            {nodesArray.splice(index, 1);
            }
            break;
          }
        } */
      });
      this.update$.next(true);
      this.centerGraph();
    });

    this.nodeService.selectedEdgeList$.subscribe((data) => {
      this.links = data;
      this.update$.next(true);
      if (this.links.length > 0) {
        this.links.forEach((element: any) => {
          if (!this.containsObject(element, linksArray.links)) {
            linksArray.links.push(element);
          }
        });
      }
    });

    this.nodeService.deselectNode$.subscribe((data) => {
      if (data) {
        this.selectedId = '';
      }
    });
  }

  containsObject(obj: any, list: any): boolean {
    let i;
    for (i = 0; i < list.length; i++) {
      if (list[i] === obj) {
        return true;
      }
    }

    return false;
  }

  ngOnInit(): void {
    this.selectednode.emit(false);
  }

  ngOnChanges(change: SimpleChanges): void {
    if (change && this.isCenterEventAvailable) {
      this.centerGraph();
    }
  }

  centerGraph(): void {
    this.center$.next(true);
  }
  fitGraph(): void {
    this.zoomToFit$.next(true);
  }
  onZoomChange($event: any): void {
    console.log('on zoom change event');
  }

  onSelect($event: any): void {
    this.selectednode.emit(false);
    console.log('on select event', $event);
    this.selectedId = $event.id;
    this.nodeService.clickListener($event);
    // this.getrules();
    this.selectedID.emit(this.selectedId);
    this.nodeService.selectedstate(this.selectedId);
  }
  onHover(): void {
    console.log('test');
    this.isHovered = true;
  }
}
