import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as S3 from 'aws-sdk/clients/s3';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface ISignedUrlBodyParams {
  filePath: string;
  fileType: string;
}
@Injectable({
  providedIn: 'root',
})
export class UploadServiceService {
  constructor(private http: HttpClient) {}

  // Upload files to S3 using presinged URL
  uploadFileToS3(
    signedURL: string,
    uploadedFile: File,
    type: string
  ): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': type,
        'Content-Encoding': 'base64',
      }),
    };
    return this.http.put<any>(signedURL, uploadedFile, httpOptions);
  }

  generateSignedUrl(
    signedUrlBodyParams: ISignedUrlBodyParams
  ): Observable<string> {
    const url = environment.GENERATE_SIGNED_S3_URL;
    return this.http.post<string>(url, signedUrlBodyParams);
  }
}
