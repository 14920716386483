<div *ngIf="items.length >= 6" class="floatRight">
  <div class="floatRight" *ngIf="carousellength <= items.length - 5">
    <button mat-icon-button (click)="next()">
      <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
  </div>
  <div *ngIf="carousellength >= 5" class="floatRight">
    <button mat-icon-button (click)="prev()">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
  </div>
</div>
<section class="carousel-wrapper" [ngStyle]="carouselWrapperStyle">
  <ul class="carousel-inner" #carousel>
    <li *ngFor="let item of items" class="carousel-item">
      <ng-container [ngTemplateOutlet]="item.tpl"></ng-container>
    </li>
  </ul>
</section>
