import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inode } from 'src/app/interfaces/node.model';
import { NodeService } from 'src/app/services/node.service';
import { map, map as nodesArray } from 'src/app/constants/node-map';
@Component({
  selector: 'app-component-flavours',
  templateUrl: './component-flavours.component.html',
  styleUrls: ['./component-flavours.component.scss'],
})
export class ComponentFlavoursComponent implements OnInit {
  public component: Inode;
  public node: Inode;
  public ingress: Inode[] = [];
  public egress: Inode[] = [];
  public nodeval: any = [];
  inboundBatch: Inode[] = [];
  outboundBatch: Inode[] = [];
  graphNode: string[] = [];
  constructor(
    public dialogRef1: MatDialogRef<ComponentFlavoursComponent>,

    @Inject(MAT_DIALOG_DATA) private data: any,
    private nodeService: NodeService
  ) {
    this.graphNode = this.nodeService.getgraphNodes();

    this.component = data.node;
    this.node = data.node;

    this.getrules();
  }

  ngOnInit(): void {}
  getrules(): void {
    if (this.component.meta && this.component.meta.ingress) {
      // intersection of rules of component and nodes on cavas
      const filteredArray: string[] = this.component.meta.ingress.filter(
        (value) => this.graphNode.includes(value)
      );
      filteredArray.forEach((element) => {
        nodesArray.forEach((x) => {
          if (element === x.id) {
            this.ingress.push(x);
          }
        });
      });
    }

    if (this.component.meta && this.component.meta.egress) {
      const filteredArray: string[] = this.component.meta.egress.filter(
        (value) => this.graphNode.includes(value)
      );

      filteredArray.forEach((element) => {
        nodesArray.forEach((x) => {
          if (element === x.id) {
            this.egress.push(x);
          }
        });
      });
    }
  }
  multiSelectInbound(batch: Inode): void {
    if (this.inboundBatch.indexOf(batch) === -1) {
      this.inboundBatch.push(batch);
    } else {
      const _index = this.inboundBatch.indexOf(batch);
      this.inboundBatch.splice(_index, 1);
    }
  }
  multiSelectOutbound(batch: Inode): void {
    // this.inboundBatch.push(batch);

    if (this.outboundBatch.indexOf(batch) === -1) {
      this.outboundBatch.push(batch);
    } else {
      const _index = this.outboundBatch.indexOf(batch);
      this.outboundBatch.splice(_index, 1);
    }
  }
  // add nodes selected from dialog to canvas
  addNodes(component: Inode): void {
    if (this.inboundBatch.length === 0 && this.outboundBatch.length === 0) {
      this.nodeService.cloneNode(component);
      this.dialogRef1.close();
    } else {
      this.inboundBatch.forEach((element) => {
        // element.id=element.id;
        component.id = component.id;

        this.nodeService.cloneNode(component);
        this.nodeService.drawEdge(component.id, element.id);
      });
      this.outboundBatch.forEach((element) => {
        component.id = component.id;
        this.nodeService.cloneNode(component);
        this.nodeService.drawEdge(element.id, component.id);
      });
      this.dialogRef1.close();
    }
  }
}
