<app-header [templateName]="templateName"></app-header>
<notifier-container style="position: absolute"></notifier-container>
<app-side-nav-container
  (centerNodeGraph)="centergraph()"
></app-side-nav-container>
<div class="example-content" style="overflow-x: hidden">
  <!-- <button (click)="saveFlowChart()" >get print</button> -->
  <div class="outerdiv" id="outerdiv">
    <app-diag
      id="flow-chart"
      (selectednode)="select($event)"
      (selectedID)="selectid($event)"
      [isCenterEventAvailable]="centreEventCalled"
    ></app-diag>
  </div>
</div>
